.box {
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
	text-align: center;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.button {
	margin: 10px;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	background-color: #007bff;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	&:hover {
		background-color: #0056b3;
	}
}
