.custom-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
        filter: brightness(90%);
    }

    &:active {
        filter: brightness(80%);
    }

    .button-image {
        object-fit: contain;
        border-radius: 5px;
    }
}